import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Tooltip } from '@material-ui/core'

import { userSelector } from '_/modules/authentication/selectors'
import useRolePermission from '_/hooks/use-role-permission'

import { orderClassifications } from './utils'

export function Classification({ classifications, size = 12 }) {
  const user = useSelector(userSelector)
  const { isAdmin, isTradesman, isIntermediary, isSAAS } = useRolePermission()

  // Ready for types 'circle' and 'square'
  const Shape = ({ color, tooltip, type = 'square' }) => (
    <Tooltip title={tooltip}>
      <div
        style={{
          height: size,
          width: size,
          backgroundColor: color,
          borderRadius: type === 'circle' ? size : 0,
        }}
      />
    </Tooltip>
  )

  const renderClassifications = useMemo(() => {
    if (classifications?.length === 0)
      return <span style={{ fontSize: '15px', lineHeight: '20px' }}>Sem classificação</span>

    const orderedClassifications = orderClassifications(classifications)

    return orderedClassifications.map(classificationItem => {
      const classification = classificationItem?.toJS() ?? classificationItem
      const classificationName = classification?.classificationName ?? ''
      const color = classification?.color?.colorInHex ?? '#fff'

      // Refera = Square - SAAS/Intermediary = Circle
      if (isAdmin) {
        return classification?.agency ? (
          <Shape
            key={classification?.id}
            color={color}
            tooltip={classificationName}
            type="circle"
          />
        ) : (
          <Shape
            key={classification?.id}
            color={color}
            tooltip={classificationName}
            type="square"
          />
        )
      }
      if ((isIntermediary || isSAAS) && classification?.agency === user?.agency) {
        return (
          <Shape
            key={classification?.id}
            color={color}
            tooltip={classificationName}
            type="circle"
          />
        )
      }
      return null
    })
  }, [classifications, user?.agency, isAdmin, isIntermediary, isSAAS])

  if (isTradesman) return null

  return <div style={{ display: 'flex', gap: 8 }}>{renderClassifications}</div>
}
