import { ROUTES } from '_/utils/constants'
import { PERMISSIONS } from '_/utils/constants/permissions'

export const getIntermediaryProfileMenuItem = intermediaryId => {
  return {
    active: true,
    icon: 'House2',
    id: 999,
    label: 'Dados da empresa',
    module: 'marketplace',
    path: `${ROUTES.AGENCY}/${intermediaryId}`,
    permissionName: PERMISSIONS.INTERMEDIARY_REGISTRY,
    type: 'secondary',
  }
}
